var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step_container"},[_c('h1',{staticClass:"step_title"},[_vm._v("TRANSACTION DETAILS 交易信息")]),_c('a-form-model',{ref:"entity_step_two",attrs:{"layout":"horizontal","rules":_vm.rules,"model":_vm.secondForm,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('div',{staticClass:"step_question_container"},[_c('div',{staticClass:"step_question_title"},[_vm._v("SOURCE OF FUNDS 资金来源")]),_c('div',{staticClass:"step_sub_title"},[_vm._v(" Please provide your source of Funds/ Wealth 请大致选择您的收入来源（这部分会在您交易外汇的时候验证） ")]),_c('BaseSOF',{attrs:{"disabled":_vm.disabled,"variable":"entity_sof_list","SelectList":_vm.entity_sof_list},on:{"onChange":_vm.handleInput}}),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPofOther),expression:"showPofOther"}],attrs:{"prop":"entity_pof_other_text","label":"其他换汇目的"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_pof_other_text,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_pof_other_text')
            }}}),_c('div',{staticClass:"textarea_desc"},[_vm._v(" I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。 ")])],1)],1),_c('div',{staticClass:"step_question_container"},[_c('div',{staticClass:"step_question_title"},[_vm._v("Account Behaviour 账号相关")]),_c('a-form-model-item',{attrs:{"prop":"entity_purpose_of_transaction_text","label":"Purpose of Transaction 换汇目的"}},[_c('a-select',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_purpose_of_transaction_text},on:{"change":function (e) {
              _vm.handleInput(e, 'entity_purpose_of_transaction_text')
            }}},_vm._l((_vm.pof_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.desc}},[_vm._v(_vm._s(item.desc))])}),1)],1),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity_purpose_of_transaction_text === 'Other'),expression:"entity_purpose_of_transaction_text === 'Other'"}],attrs:{"prop":"entity_purpose_of_transaction_other_text","label":"其他换汇目的"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"placeholder":"","value":_vm.entity_purpose_of_transaction_other_text,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_purpose_of_transaction_other_text')
            }}}),_c('div',{staticClass:"textarea_desc"},[_vm._v(" I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。 ")])],1),_c('a-form-model-item',{attrs:{"prop":"entity_transaction_country_list","label":"List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"placeholder":"","value":_vm.entity_transaction_country_list,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_transaction_country_list')
            }}})],1)],1),_c('div',{staticClass:"step_question_container"},[_c('div',{staticClass:"step_question_title"},[_vm._v(" Transaction and Payment 交易和付款 ")]),_c('a-form-model-item',{attrs:{"prop":"entity_num_of_anual_transactions","label":"Approx. number of annual transactions 大约每年交易次数是多少"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_num_of_anual_transactions,"placeholder":""},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_num_of_anual_transactions')
            }}})],1),_c('a-form-model-item',{attrs:{"prop":"entity_frequency_of_transaction","label":"Approx. number & frequency of transactions 大约交易频率是多少"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_frequency_of_transaction,"placeholder":""},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_frequency_of_transaction')
            }}}),_c('div',{staticClass:"textarea_desc"},[_vm._v(" I.e. 5 weekly / 3 monthly / 2 quarterly ")])],1),_c('a-form-model-item',{attrs:{"prop":"entity_total_volumn_of_trasaction_per_year","label":"Approx. number tade volume (in NZD) 大约每年交易金额是多少（纽币）"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_total_volumn_of_trasaction_per_year},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_total_volumn_of_trasaction_per_year')
            }}})],1),_c('a-form-model-item',{attrs:{"prop":"entity_average_volume_of_per_transaction","label":"Approx. average transaction volume (in NZD) 大约平均每笔交易金额是多少（纽币）"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_average_volume_of_per_transaction},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_average_volume_of_per_transaction')
            }}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }